import { graphql } from 'gatsby'
import * as React from 'react'
import Konsultacje, { Props } from './konsultacje'

export const query = graphql`
  query PLDydaktykaQuery {
    allContentXlsxPlConsultations {
      nodes {
        name
        place
        platform
        time1
        time2
        time3
      }
    }
  }
`

const Dydaktyka = (props: Props) => <Konsultacje {...props} />
export default Dydaktyka
